<template>
  <div class="">
    <div>
      <div style="background-color: #000">
        <div class="row m-0" style="height:50px;">
          <div class="col-3">
            <div class="d-flex">
              <ul
                class="list-inline text-muted mx-auto justify-content-center"
                style="margin: 15px !important"
              >
                <li class="list-inline-item" style="margin-right:15px;">
                  <a class="reset-anchor text-white" href="#"
                    ><i
                      class="fab fa-facebook-f fa-5x"
                      style="font-size: 2em !important"
                    ></i
                  ></a>
                </li>
                <li class="list-inline-item"  style="margin-right:15px;">
                  <a class="reset-anchor text-white" href="#"
                    ><i
                      class="fab fa-instagram fa-2x"
                      style="font-size: 2em !important"
                    ></i
                  ></a>
                </li>
                <li class="list-inline-item" style="margin-right:15px;">
                  <a class="reset-anchor text-white" href="#"
                    ><i
                      class="fab fa-twitter fa-2x"
                      style="font-size: 2em !important"
                    ></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a class="reset-anchor text-white" href="#"
                    ><i
                      class="fab fa-linkedin fa-2x"
                      style="font-size: 2em !important"
                    ></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          

          
          <div class="col-7 justify-content-center">
            <div class="row" style="">
            <div class="col-4"></div>
            <div class="col-3"></div>

              <div class="col-2" style="margin-top: 25px;float:right;">
                <a
                  class="
                    reset-anchor
                    text-small text-white
                    justify-content-center
                  "
                  href="#"
                  ><h6 style="float:right;" class="text-white">  P: + 216 71 448 271</h6></a
                >
              </div>
              <div class="col-3" style="margin-top:25px;padding-right:50px;padding-left:0px;float:right;">
                <a
                  class="reset-anchor text-small text-white"
                  style="margin-top: 20px"
                  href="#"
                  ><h6 style="float:right;" class="text-white"> T: + 216 72 448 273</h6>
                </a>
              </div>
            </div>
          </div>
          <div class="col-2 justify-content-right" style=""><div style="padding-left:0px;float:right;" class="row"> 
            <router-link to="/contact">
             <h4 style="margin-top: 20px" class="text-white">Contact US</h4>
            </router-link>
           
            
            <router-link to="/about">
             <h4 style="margin-top: 20px;margin-left: 20px;margin-right:50px" class="text-white">About US</h4>
            </router-link>
           </div>
           </div>
        </div>
      </div>
      <fixed-header>
        <div style="background-color: #80ced6">
          <div class="">
            <div class="row topnav" style="background-color: #fff">
              <div class="col-2">
                <div class="row justify-content-center">
                  <router-link class="navbar-brand" to="/">
                    <img src="/logo.PNG" />
                    <span class="hidden-xs hidden-sm"></span
                  ></router-link>
                </div>
              </div>
              <div
                class="col-10 text-dark clearfix"
                style="background-color: #fff"
              >
                <div class="container-fluid">
                  <nav class="navbar navbar-expand-md topnav-menu">
                    <div
                      class="collapse navbar-collapse"
                      style="margin-top: 50px"
                      id="topnav-menu-content"
                    >
                      <ul style="white-space: nowrap" class="navbar-nav">
                        <li
                          style="display: inline-block"
                          class="nav-item dropdown"
                        >
                          <router-link
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000"
                            to="/production"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Production Equipment
                          </router-link>
                          <div
                            style="
                              width: 725%;
                              height: 300px;
                              margin-left: -200px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:30%:">
                                <li style="height: 200px">
                                  <img
                                    class="d-block img-fluid"
                                    src="../assets/images/product/ERSA/st.png"
                                    width="250"
                                    height="100"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 25%">
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/board_handling_system"
                                      ><strong
                                        >Bord handling system
                                        </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/board_handling_system/loading_unloading"
                                      >Loading/Unloading
                                    </router-link>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/board_handling_system/conveyor"
                                      >Conveyor
                                    </router-link>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/board_handling_system/buffer"
                                      >Buffer
                                    </router-link>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/board_handling_system/Flip_Turning"
                                      >Flip/Turning
                                    </router-link>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/board_handling_system/otherss"
                                      >Other
                                    </router-link>
                                  </h5>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="screenp()"
                                      ><strong>Screen Printer </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/chip_mounter"
                                      ><strong>Chip Mounter</strong>
                                    </router-link>
                                  </h4>
                                </li>
                              </div>

                              <div style="width: 25%">
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                     @click="reflowoven()"
                                      ><strong>Reflow Oven</strong>
                                    </a>
                                  </h4>
                                </li>

                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="slectivesoldering()"
                                      ><strong>Selective Soldering </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="wavesoldering()"
                                      ><strong>Wave Soldering </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/glue_potting"
                                      ><strong>Glue potting Machine</strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/glue_dispensing"
                                      ><strong>
                                        Glue Dispensing Machine
                                      </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="lasermarking()"
                                      ><strong> Laser Marking Machine </strong>
                                    </a>
                                  </h4>
                                </li>
                              </div>

                              <div style="width: 25%">
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/routerdepann"
                                      ><strong>Router Depaneling </strong>
                                    </router-link>
                                  </h4>
                                </li>

                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="xray()"
                                      ><strong> X Ray Machine </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/production_equipement/procleaning"
                                      ><strong>Cleaning Equipment </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="coating()"
                                      ><strong>Coating And Dispensing</strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="humidity()"
                                      ><strong> Humidity Management </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="laserselective()"
                                      ><strong
                                        >Laser Selective Soldering
                                      </strong>
                                    </a>
                                  </h4>
                                </li>
                              </div>
                            </div>
                          </div>
                        </li>

                         <!--2-->
                        <li class="nav-item dropdown">
                          <router-link
                         
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000;font-size:14px;"
                            to="/km"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            KM Corporate
                          </router-link>
                          <div
                            style="
                              width: 1030%;
                              height: 300px;
                              margin-left: -360px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:20%;margin-left:20px;">
                                <li style="height: 200px">
                                  <img
                                    class="d-block img-fluid"
                                    src="https://kmcorporate.com/wp-content/uploads/2021/07/Miniapplicatore-Meccanico.jpg"
                                    width="350"
                                    height="200"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 25%; margin-right: 170px">
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/idc_solutions"
                                      ><strong>Idc Solutions</strong>
                                    </router-link>
                                  </h4>
                                </li>

                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/automatic_solutions"
                                      ><strong>Automatic Solutions</strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/crimpingunit"
                                      ><strong
                                        >Crimping Unit
                                        </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/accessoiries"
                                      ><strong
                                        >Accessoiries
                                        </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/accessoiries/km100"
                                      >KM100
                                    </router-link>
                                  </h5>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/accessoiries/km6020"
                                      >KM6020
                                    </router-link>
                                  </h5>
                                </li>
                           
                              </div>

                              <div style="width: 25%">
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/mini_applicators"
                                      ><strong>Mini Applicatores</strong>
                                    </router-link>
                                  </h4>
                                </li>

                                
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/mini_applicators/standard"
                                      >Standard Mini Applicator
                                    </router-link>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/mini_applicators/pneumatic"
                                      >Pneumatic Mini Applicator
                                    </router-link>
                                  </h5>
                                </li>
                                 <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/mini_applicators/ferrules"
                                      >Ferruless Mini Applicator
                                    </router-link>
                                  </h5>
                                </li>
                                 <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/mini_applicators/special"
                                      >Special Mini Applicator
                                    </router-link>
                                  </h5>
                                </li>
                                 <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/km/mini_applicators/splice"
                                      >Splice Mini Applicator
                                    </router-link>
                                  </h5>
                                </li>

                                
                            
                              </div>
                            </div>
                          </div>
                        </li>





                        

                        <!--2-->
                        <li class="nav-item dropdown">
                          <router-link
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000"
                            to="/soldering"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Soldering and Rework
                          </router-link>
                          <div
                            style="
                              width: 675%;
                              height: 300px;
                              margin-left: -480px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:20%;margin-left:20px;">
                                <li style="height: 200px">
                                  <img
                                    class="d-block img-fluid"
                                    src="../assets/images/product/metcal/Desoldering_Soldering_Systems.jpg"
                                    width="250"
                                    height="100"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 25%; margin-right: 170px">
                                <li>
                                  <h4>
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="fumeextractor()"
                                      ><strong>Fume Extractor</strong>
                                    </a>
                                  </h4>
                                </li>

                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="solderpot()"
                                      ><strong>Solder Pot </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/soldering_rework/soldering"
                                      ><strong
                                        >Soldering
                                        </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="gtseries()"
                                      >GT series
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="cvseries()"
                                      >CV Series
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="mxseries()"
                                      >MX Series
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="mfrseries()"
                                      >MFR Series
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="psseries()"
                                      >PS Series
                                    </a>
                                  </h5>
                                </li>
                              </div>

                              <div style="width: 25%">
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/soldering_rework/desoldering"
                                      ><strong>Desoldering</strong>
                                    </router-link>
                                  </h4>
                                </li>

                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/soldering_rework/convection_rework"
                                      ><strong>Convection Rework </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="hotairtools()"
                                      >Hot Air Tools
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="preheaters()"
                                      >Preheaters
                                    </a>
                                  </h5>
                                </li>

                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="rework()"
                                      ><strong>Rework </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/commingsoon"
                                      ><strong>Robotic Soldering</strong>
                                    </router-link>
                                  </h4>
                                </li>
                              </div>
                            </div>
                          </div>
                        </li>












                        <!--3-->
                        <li class="nav-item dropdown">
                          <router-link
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000"
                            to="/inspection"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Inspection and Testing
                          </router-link>
                           <div
                            style="
                              width: 655%;
                              height: 300px;
                              margin-left: -660px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:20%;margin-left:20px;">
                                <li style="height: 300px">
                                  <img 
                                    class="d-block img-fluid"
                                    src="../assets/images/product/Saki/product1_3Di-MS2_pc.png"
                                    width="250"
                                    height="400"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 25%">
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/commingsoon"
                                      ><strong
                                        >Electronic Concentration Analysis
                                        
                                      </strong>
                                    </router-link>
                                  </h4>
                                </li>
                              
                                
                              
                             
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="ioniccontaminationtesting()"
                                      ><strong>Ionic Contamination Testing </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="spi()"
                                      ><strong>SPI </strong>
                                    </a>
                                  </h4>
                                </li>
                                 <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/inspection_test/aoi"
                                      ><strong>AOI </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                      <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="twodaoi()"
                                      >2D AOI 
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="threedaoi()"
                                      >3D AOI 
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="inspectionxray()"
                                      ><strong> INSPECTION X RAY </strong>
                                    </a>
                                  </h4>
                                </li>
                                 
                              </div>

                              <div style="width: 25%">
                               <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/inspection_test/microscope"
                                      ><strong> Microscope</strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="tabletopdigi()"
                                      >Tabletop Digi Professional Microscopes
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                     
                                      
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="portabledigital()"
                                      >Portable Digital Microscope With Lcd Screen  
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="usbdigital()"
                                      >USB Digital Microscope
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="baroscop()"
                                      >Baroscope
                                    </a>
                                  </h5>
                                </li>
                             
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="testsolution()"
                                      ><strong>Test Solution </strong>
                                    </a>
                                  </h4>
                                </li>
                               
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="thermalprofiling()"
                                      ><strong>
                                        Thermal Profiling 
                                      </strong>
                                    </a>
                                  </h4>
                                </li>
                              
                              </div>

                              <div style="width: 25%">
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/inspection_test/heat_cold_testing"
                                      ><strong>Heat Cold Testing </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                  <li>
                                  <h5
                                    style="border-top: 1px solid #d3d3d3;">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="temperature()"
                                      >Temperature Testing Chambers  
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="temperatureandhum()"
                                      >Temperature & Humidity Chambers
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="vacumdrying()"
                                      >Vacuum Drying Ovens
                                    </a>
                                  </h5>
                                </li>
                               
                             
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                       @click="testmesure()"
                                      ><strong> Test and Measurement  </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="icprogramming()"
                                      ><strong> IC programming  </strong>
                                    </a>
                                  </h4>
                                </li>
                           
                              </div>
                            </div>
                          </div>
                        </li>









                        <!--4-->
                        <li class="nav-item dropdown">
                          <router-link
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000"
                            to="/esdtools"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            ESD & TOOLS
                          </router-link>
                         <div
                            style="
                              width: 985%;
                              height: 300px;
                              margin-left: -840px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:20%;margin-left:20px;">
                                <li style="height: 300px">
                                  <img 
                                    class="d-block img-fluid"
                                    src="../assets/images/product/suitin/20190722165028.jpg"
                                    width="250"
                                    height="400"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 25%">
                                <li>
                                  <h4>
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/heelgrounders"
                                      ><strong
                                        >Anti Static Heel Grounders
                                        
                                      </strong>
                                    </router-link>
                                  </h4>
                                </li>
                              
                                
                              
                             
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/wriststraps"
                                      ><strong>Antistatic Wrist Straps  </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/cords"
                                      ><strong>Earth Grounding Cords </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                 <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/mats_and_kits"
                                      ><strong>ESD Mats And Kits </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                   
                              
                                 <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="esdclothing()"
                                      ><strong> ESD Clothing</strong>
                                    </a>
                                  </h4>
                                </li>
                                 
                              </div>

                              <div style="width: 25%">
                               <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/gloves"
                                      ><strong> ESD Gloves</strong>
                                    </router-link>
                                  </h4>
                                </li>
                                
                               
                              
                               
                             
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/brusheswipes"
                                      ><strong>ESD Brush & Wipes </strong>
                                    </router-link>
                                  </h4>
                                </li>
                               
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/handtools"
                                      ><strong>
                                        ESD Hand Tools 
                                      </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/chairs"
                                      ><strong>ESD Chairs </strong>
                                    </router-link>
                                  </h4>
                                </li>
                               <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/stationarylamps"
                                      ><strong>ESD Stationary & Magnifier Lamps </strong>
                                    </router-link>
                                  </h4>
                                </li>
                              </div>

                              <div style="width: 25%">
                                
                                  
                               
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/hloderrack"
                                      ><strong> ESD PCB Holders & Racks  </strong>
                                    </router-link>
                                  </h4>
                                </li>
                               
                             
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/tester"
                                      ><strong> Tester & Tapes & Others  </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/esdtools/wire"
                                      ><strong> Wire Sheving & Cage   </strong>
                                    </router-link>
                                  </h4>
                                </li>
                           
                              </div>
                            </div>
                          </div>
                        </li>




















                        <!--5-->
                        <li class="nav-item dropdown">
                          <router-link
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000"
                            to="/materials"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Materials
                          </router-link>
                         <div
                            style="
                              width: 1335%;
                              height: 300px;
                              margin-left: -960px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:20%;margin-left:20px;">
                                <li style="height: 300px">
                                  <img 
                                    class="d-block img-fluid"
                                    src="../assets/images/product/DCT/D250.jpg"
                                    width="250"
                                    height="400"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 40%">
                        
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="cleaningwaterbased()"
                                      ><strong>Cleaning Water Based  </strong>
                                    </a>
                                  </h4>
                                </li>
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="cleaningagentsolvent()"
                                      ><strong>Cleaning Agent Solvent Based </strong>
                                    </a>
                                  </h4>
                                </li>
                                 <li>
                                  <h4 style="margin-bottom: 10px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="solderconsumable()"
                                      ><strong>Solder Consumables </strong>
                                    </a>
                                  </h4>
                                </li>
                                   
                              
                                 <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="otherproductcleaning()"
                                      ><strong> Other Product </strong>
                                    </a>
                                  </h4>
                                </li>
                                 
                              </div>

                              <div style="width: 35%">
                               <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/materials/spray_solution"
                                      ><strong> Spray Solution </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="ecscleaners()"
                                      >ECS-Cleaners
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="protectivelubricating()"
                                      >Protective Lubricating Agent
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="lacquers()"
                                      >Lacquers
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="specialspray()"
                                      >Special Spray
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="elixhandcleaning()"
                                      >Elix Hand Cleaning Wipes
                                    </a>
                                  </h5>
                                </li>
                               
                              
                               
                             
                              </div>

                             
                            </div>
                          </div>
                        </li>





































                        <!--6-->
                        <li class="nav-item dropdown">
                          <router-link
                            class="nav-link dropdown-toggle arrow-none"
                            style="color: #000"
                            to="/auxilaryequipement"
                            id="topnav-apps"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Auxilary Equipment
                          </router-link>
                          <div
                            style="
                              width: 745%;
                              height: 300px;
                              margin-left: -1050px;
                            "
                            class="dropdown-menu"
                            aria-labelledby="topnav-apps"
                          >
                            <div class="row">
                              <div style="width:20%;margin-left:20px;">
                                <li style="height: 300px">
                                  <img 
                                    class="d-block img-fluid"
                                    src="../assets/images/product/fiamgroupe/uu.jpg"
                                    width="250"
                                    height="400"
                                    alt="category.name"
                                  />
                                </li>
                              </div>
                              <div style="width: 25%">
                          
                          
                                 <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/auxiliary_equipement/adhesif_and_liquid_dispenser"
                                      ><strong>Adhesif and liquid dispenser  </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                  <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="dx250()"
                                      >DX-250 
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="dx255()"
                                      >DX-255 
                                    </a>
                                  </h5>
                                </li>
                              
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="dx350()"
                                      >DX-350
                                    </a>
                                  </h5>
                                </li>
                                 <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/auxiliary_equipement/electriquescrew"
                                      ><strong> Electric Torque Screwdrivers  </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                 <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a 
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="logiform()"
                                      ><strong>Modular System Equipement </strong>
                                    </a>
                                  </h4>
                                </li>
                                
                                 
                              </div>

                              <div style="width: 25%">
                               <li>
                                  <h4 style="margin-bottom: 15px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/auxiliary_equipement/pneumatique_screw_device"
                                      ><strong> Pneumatic Screw Devices</strong>
                                    </router-link>
                                  </h4>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="straightairscrewdrivers()"
                                      >Straight Air Screwdrivers
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;                        
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="pistolairscrewdrivers()"
                                      >Pistol Air Screwdrivers
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="forwardpistol()"
                                      >Forward Pistol Grip Air Screwdrivers
                                    </a>
                                  </h5>
                                </li>
                               
                                                    <li>
                                                      <h5 style="border-top: 1px solid #d3d3d3">
                                                        <a
                                                          class="dropdown-item"
                                                          style="color: #000"
                                                         @click="forwardpistol3position()"
                                                          >Forward Pistol Grip Air Screwdrivers With 3 Position 
                                                        </a>
                                                      </h5>
                                                    </li>
                                
                                                    <li>
                                                      <h5 style="border-top: 1px solid #d3d3d3">
                                                        <a
                                                          class="dropdown-item"
                                                          style="color: #000"
                                                          @click="pistolupgrip()"
                                                          >Pistol UpGrip Air Screwdrivers
                                                        </a>
                                                      </h5>
                                                    </li>
                               
                                                    <li>
                                                      <h5 style="border-top: 1px solid #d3d3d3">
                                                        <a
                                                          class="dropdown-item"
                                                          style="color: #000"
                                                          @click="angelairscrew()"
                                                          >Angel Air Nutrunners
                                                        </a>
                                                      </h5>
                                                    </li>
                                
                                 <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                       @click="straightairscrewsuction()"
                                      >Straight Air Screwdrivers With Srew Suction Device
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="airtighteningsystems()"
                                      >Air Tightening Systems
                                    </a>
                                  </h5>
                                </li>
                             
                             
                               
                                
                              
                              </div>

                              <div style="width: 25%">
                                <li>
                                  <h4 style="margin-bottom: 10px">
                                    <router-link
                                      class="dropdown-item"
                                      style="color: #000"
                                      to="/auxiliary_equipement/perform_machine"
                                      ><strong>Preform Machine </strong>
                                    </router-link>
                                  </h4>
                                </li>
                                  <li>
                                  <h5
                                    style="border-top: 1px solid #d3d3d3;">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="performaxial()"
                                      >Axials
                                    </a>
                                  </h5>
                                </li>
                                  <li>
                                  <h5
                                    style="border-top: 1px solid #d3d3d3;">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="performradials()"
                                      >Radials
                                    </a>
                                  </h5>
                                </li>
                                  <li>
                                  <h5
                                    style="border-top: 1px solid #d3d3d3;">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                       @click="pcboardseparators()"
                                      >Pc Board Separators  
                                    </a>
                                  </h5>
                                </li>
                                  <li>
                                  <h5
                                    style="border-top: 1px solid #d3d3d3;">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                     @click="speciallinemachines()"
                                      >Special Line Machines 
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5 style="border-top: 1px solid #d3d3d3">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                       @click="countersandscales()"
                                      >Counters And Scales
                                    </a>
                                  </h5>
                                </li>
                                <li>
                                  <h5
                                    style="
                                      border-top: 1px solid #d3d3d3;
                                      border-bottom: 1px solid #d3d3d3;
                                      margin-bottom: 10px;
                                    "
                                  >
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="performotherproducts()"
                                      >Other Products
                                    </a>
                                  </h5>
                                </li>
                               
                             
                                <li>
                                  <h4 style="margin-bottom: 15px">
                                    <a
                                      class="dropdown-item"
                                      style="color: #000"
                                      @click="solderingmixingmachines()"
                                      ><strong> Soldering Mixing Machines</strong>
                                    </a>
                                  </h4>
                                </li>
                             
                           
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <!-- mobile menu -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </fixed-header>
      <!-- END nav -->
    </div>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import axios from "axios";

export default {
  data() {
    return {
      user: null,
      categories: [],
      search: "",
      selectproduct: "",
    };
  },
  components: {
    FixedHeader,
  },

  methods: {
    slectivesoldering(){
      window.location.href = "https://www.kurtzersa.com/products/electronics-production/soldering-machines/selective-systems";
     
    },
    wavesoldering(){
      window.location.href = "https://www.kurtzersa.com/products/electronics-production/soldering-machines/wave-soldering-systems";
     
    },
    lasermarking(){
      window.location.href = "https://osai-as.com/en/neomark-easy";
     
    },
    xray(){
      window.location.href = "https://www.sakicorp.com/en/product/3daxi/";
      
    },
    coating(){
      window.location.href = "https://anda.com.hk/en/products/main";
   
    },
    humidity(){
      window.location.href = "https://dr-storageamerica.com/cabinets/x2m-cabinets/";
     
    },
    laserselective(){
      window.location.href = "https://www.seica.com/solutions/laser-selective-soldering/";
   
    },
    fumeextractor(){
      window.location.href = "https://store.metcal.com/en-us/shop/fume-extraction/fume-extraction-systems/";
     
    },
    solderpot(){
      window.location.href = "http://www.waterun.com.cn/En/Products/index?cid=53";
     
    },
    reflowoven(){
      window.location.href = "https://www.kurtzersa.com/products/electronics-production/soldering-machines/reflow-soldering-systems";
     
    },
    screenp(){
      window.location.href = "https://www.kurtzersa.com/products/electronics-production/stencil-printers";
 
    },




     gtseries(){
      window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/gt-series/";
     
    },
     cvseries(){
      window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/cv-series/CV-5210";
     
    },
     mxseries(){
      window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mx-series/MX-5210";
   
    },
     mfrseries(){
      window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mfr-series/MFR-1110";
    
    },
     psseries(){
      window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/ps-series/PS-900";
   
    },
     hotairtools(){
      window.location.href = "https://store.metcal.com/en-us/shop/convection-rework/hot-air-tools/";
    
    },
     preheaters(){
      window.location.href = "https://store.metcal.com/en-us/shop/convection-rework/preheaters/";
    
    },
     rework(){
      window.location.href = "https://www.fonton.com/en/product-inner.php?id=117";
 
    },
     ioniccontaminationtesting(){
      window.location.href = "https://www.dct.cleaning/measuring-instruments-process-control/";

    },
     spi(){
      window.location.href = "https://www.sakicorp.com/en/product/3dspi/";
   
    },
     twodaoi(){
      window.location.href = "https://www.sakicorp.com/en/product/2daoi/";
    
    },
     threedaoi(){
      window.location.href = "https://www.sakicorp.com/en/product/3daoi/";
     
    },
     inspectionxray(){
      window.location.href = "https://www.sakicorp.com/en/product/3daxi/";
    
    },
     tabletopdigi(){
      window.location.href = "http://www.vitiny-usa.com/digi-tabletop-microscopes.html";
      
    },
    portabledigital(){
      window.location.href = "http://www.vitiny-usa.com/portable-digital-microscopes-with-lcd-screen.html";
 
    },
    usbdigital(){
      window.location.href = "http://www.vitiny-usa.com/usb-digital-microscopes1.html";
     
    },
    baroscop(){
      window.location.href = "http://www.vitiny-usa.com/vt400-videoscope.html";

    },
    testsolution(){
      window.location.href = "https://www.seica.com/solutions/";
 
    },
    thermalprofiling(){
      window.location.href = "https://gp-ics.com/products/measuring-systems/";

    },
    temperature(){
      window.location.href = "https://www.sinerji-grup.com/temperature-testing-chambers-ur-332";
  
    },
    temperatureandhum(){
      window.location.href = "https://www.sinerji-grup.com/temperature-humidity-chambers ";
   
    },
    vacumdrying(){
      window.location.href = "https://www.sinerji-grup.com/vacuum-drying-ovens";

    },
    icprogramming(){
      window.location.href = "https://www.dediprog.com/category/universal-programmer";

    },
    cleaningwaterbased(){
      window.location.href = "https://www.dct.cleaning/decotron-water-based-cleaning-agents/";
  
    },
    cleaningagentsolvent(){
      window.location.href = "https://www.dct.cleaning/proton-solvent-based-cleaning-agents/";
     
    },

otherproductcleaning(){
  window.location.href = "https://www.dct.cleaning/other-products/";
  
    },

solderconsumable(){
  window.location.href = "https://interflux.com/en";

    },

ecscleaners(){
  window.location.href = "https://ecs-chemicals.com/ecs-cleaners/";

  
 
    },

protectivelubricating(){
  window.location.href = "https://ecs-chemicals.com/protective-lubricating-agent/ ";
 
    },

lacquers(){
  window.location.href = "https://ecs-chemicals.com/lacquers";

    },

specialspray(){
  window.location.href = "https://ecs-chemicals.com/special-sprays/";
  
    },

elixhandcleaning(){
  window.location.href = "https://ecs-chemicals.com/elix-hand-cleaning-wipes/";
    
    },

dx250(){
  window.location.href = "https://store.metcal.com/en-us/shop/fluid-dispensing/dispensers/DX-250";

    },

dx255(){
  window.location.href = "https://store.metcal.com/en-us/shop/fluid-dispensing/dispensers/DX-255";
  
    },

dx350(){
  window.location.href = "https://store.metcal.com/en-us/shop/fluid-dispensing/dispensers/DX-350";
      
    },

straightairscrewdrivers(){
  window.location.href = "https://www.fiamgroup.com/en/products/straight-air-screwdrivers/";
    
    },

pistolairscrewdrivers(){
  window.location.href = "https://www.fiamgroup.com/en/products/pistol-air-screwdrivers/";
  
    },

forwardpistol(){
  window.location.href = "https://www.fiamgroup.com/en/products/forward-pistol-grip-air-screwdrivers/";
    
    },

forwardpistol3position(){
  window.location.href = "https://www.fiamgroup.com/en/products/forward-pistol-grip-air-screwdrivers-with-3-position-air-inlet/";
     
    },
    pistolupgrip(){
      window.location.href = "https://www.fiamgroup.com/en/products/pistol-upgrip-air-screwdrivers/";
  
    },
    angelairscrew(){
      window.location.href = "https://www.fiamgroup.com/en/products/angle-air-nutrunners/";
    
    },
    straightairscrewsuction(){
      window.location.href = "https://www.fiamgroup.com/en/products/straight-air-screwdrivers-with-screw-suction-device/ ";
   
    },
    airtighteningsystems(){
      window.location.href = "https://www.fiamgroup.com/en/products/tom-monitoring-unit-for-the-tightening-cycle/";
   
    },
    performaxial(){
      window.location.href = "https://www.olamef.net/en-gb/axials/";

    },
    performradials(){
      window.location.href = "https://www.olamef.net/en-gb/radials/";
 
    },
    pcboardseparators(){
      window.location.href = "https://www.olamef.net/en-gb/pc-board-separators/";
  
    },
    speciallinemachines(){
      window.location.href = "https://www.olamef.net/en-gb/special-line-machines/";
    
    },
    countersandscales(){
      window.location.href = "https://www.olamef.net/en-gb/counters-and-scales/";

    },
    performotherproducts(){
      window.location.href = "https://www.olamef.net/en-gb/other-products/";

    },
    solderingmixingmachines(){
      window.location.href = "http://www.genitec.com.tw/gam-60-e.htm";
  
    },
     esdclothing(){
      window.location.href = "https://www.ebruzen.com.tr/en/antistatic-esd-labcoat-smock-lc05/27/3/5";

    },
    testmesure(){
      window.location.href = "https://www.seica.com/solutions/";
  
    },
    logiform(){
      window.location.href = "http://www.leanlab.kr/showroom/picture_suite.php/";

    },









    logout() {
      localStorage.removeItem("token");
      this.$router.go();
    },
    // offcanvas mobilemenu
    mobiletoggleClass(addRemoveClass, className) {
      const el = document.querySelector("#offcanvas-menu");
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },

  async created() {
    const response = await axios.get("http://127.0.0.1:8000/api/user", {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    this.user = response.data;
    console.log(this.user.name);
  },
};
</script>

<style lang="scss">
input::-webkit-input-placeholder {
  font-size: 10px;
  line-height: 3;
}
.vue-fixed-header--isFixed {
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 999;
  position: fixed;
  margin: 0px;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  animation: 9000ms cubic-bezier(20, 1, 0.22, 1) 0s normal none 1 running
    fadeInDown;
}
.backgroundcolor {
  background-image: url("../../public/assets/img/Background.jpg");
  width: XXpx;
  height: XXpx;
}
</style>